const TrainingDept = () => import("../views/training-dep.vue");

export default [
  {
    path: "/employee/training-dep/list",
    component: TrainingDept,
    // meta: { requiresAuth: true },
    name: "trainings-dep",
  },
];
