const ListMyDepartments = () => import("../views/ListMyDepartments.vue");

export default [
  {
    path: "my-departments/list",
    component: ListMyDepartments,
    meta: { requiresAuth: true, permissions: "departments,list_departments" },
    name: "my-department",
  },
];
