<template>
  <v-autocomplete
    v-model="selectedItems"
    :items="displayItems"
    :loading="loading"
    :search-input.sync="search"
    :label="label"
    :placeholder="placeholder"
    item-text="text"
    item-value="value"
    chips
    clearable
    hide-details
    multiple
    small-chips
    :return-object="returnObject"
    outlined
    dense
    persistent-hint
    @scroll="onScroll"
  >
    <!-- Select Visible Items Header -->
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggleSelectVisible"
      >
        <v-list-item-action>
          <v-checkbox
            :input-value="areVisibleItemsSelected"
            :indeterminate="hasPartialVisibleSelection"
            color="primary"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select Visible Items
            <span class="text-caption grey--text">
              ({{ displayItems.length }} items)
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>

    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results found
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index < 3"
        :key="item.value"
        small
        class="mr-1"
        close
        @click:close="removeItem(item)"
      >
        {{ getEmployeeById(item) }}
      </v-chip>
      <span
        v-if="index === 3"
        class="grey--text text-caption"
      >
        (+{{ selectedItems.length - 3 }} others)
      </span>
    </template>

    <template v-slot:append-item>
      <v-list-item
        v-if="hasMoreItems"
        
        :loading="loading"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between">
            <v-btn x-small text class="px-1" @click="loadMoreItems(false)">
              <span class="text-capitalize caption">Load More</span>
            </v-btn>
            <v-btn x-small text class="px-1" @click="loadMoreItems(true)">
              <span class="text-capitalize caption">Load All</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
// import { gql } from 'graphql-tag'
import debounce from 'lodash/debounce'
import GET_EMPLOYEES from "@/modules/entity/modules/employee/graphql/query/GetEmployeesList.gql";

export default {
  name: 'MultiSelectAutocomplete',
  
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hasEmail: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    notIds: {
      type: Array,
      default: () => [],
    },
    positionsIds: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Start typing to search...'
    }
  },

  data: () => ({
    items: [],
    selectedItems: [],
    selectedItemsObjects: [],
    areVisibleItemsSelected1: false,
    loading: false,
    search: null,
    totalItems: 0,
    page: 1,
    itemsPerPage: 20,
  }),

  computed: {
    displayItems() {
      // Combine search results with selected items to ensure they're always visible
      const searchItems = this.items.filter(item => 
        !this.selectedItems.some(selected => selected === item.value)
      )
      return [...this.selectedItemsObjects, ...searchItems]
    },

    

    // Check if all currently visible items are selected
    areVisibleItemsSelected() {
      return this.displayItems.length > 0 && 
             this.displayItems.every(item => 
               this.selectedItems.some(selected => selected === item.value)
             )
    },

    // Check if some but not all visible items are selected
    hasPartialVisibleSelection() {
      const selectedVisibleCount = this.displayItems.filter(item =>
        this.selectedItems.some(selected => selected === item.value)
      ).length

      return selectedVisibleCount > 0 && selectedVisibleCount < this.displayItems.length
    },

    // Determine if there are more items to load
    hasMoreItems() {
      return this.items.length < this.totalItems
    },
  },

  watch: {
    search(val) {
      if (this.loading) return
      this.debouncedSearch(val)
    },

    positionsIds() {
      this.searchItems('', this.ids)
    },
    
    value: {
      immediate: true,
      handler(newVal) {
        this.selectedItemsObjects = this.items.filter(item => newVal.includes(item.value));
        this.selectedItems = newVal
      },
    },
    
    selectedItems(newVal) {
      // this.selectedItemsObjects = this.items.filter(item => newVal.includes(item.value));
      const selected = this.items.filter(item => newVal.includes(item.value));
      this.$emit('input', newVal)
      this.$emit('change', selected)
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchItems, 500)
    // Initial load
    this.searchItems('', this.ids)
  },

  methods: {
    async searchItems(search, ids) {
      this.loading = true
      this.page = 1
      const isEdit = ids && ids.length > 0;
      try {
        const { data } = await this.$apollo.query({
          query: GET_EMPLOYEES,
          variables: {
            filterEmployeeInput: {
              search,
              employeesIds: isEdit ? ids : [],
              notIncludeEmployeesIds: this.notIds && this.notIds.length > 0 ? this.notIds : [],
              positionsIds: this.positionsIds && this.positionsIds.length > 0 ? this.positionsIds : [],
              sectionId: undefined,
              teamId: undefined,
              gender: undefined,
              page: this.page,
              pageSize: this.itemsPerPage,
              hasEmail: this.hasEmail,
            }
          },
        })

        if (isEdit) {
          this.selectedItems = data.employees.employees.filter(item => 
            this.ids.some(selected => selected === item.id)
          ).map(mapEmployee => mapEmployee.id);
          
          this.selectedItemsObjects = data.employees.employees.filter(item => 
            this.ids.some(selected => selected === item.id)
          ).map(mapEmployee => {
            return { text: `${mapEmployee.firstName} ${mapEmployee.lastName}`, value: mapEmployee.id }
          });
        }

        // Keep selected items and add new search results
        const newItems = data.employees.employees.map((mapEmployee) => {
          return { text: `${mapEmployee.firstName} ${mapEmployee.lastName}`, value: mapEmployee.id }
        });

        this.items = [...this.selectedItemsObjects, ...newItems]
        this.totalItems = data.employees.totalRows
      } catch (error) {
        console.error('Error fetching items:', error)
        // Handle error appropriately (show snackbar, etc.)
      } finally {
        this.loading = false
        this.$emit('done')
      }
    },

    async loadMoreItems(isLoadAll = false) {
      if (this.loading) return
      this.page += 1
      
      const offset = this.page * this.itemsPerPage
      
      // Don't load more if we've loaded everything
      if (offset >= this.totalItems) return
      
      this.loading = true
      
      try {
        const { data } = await this.$apollo.query({
          query: GET_EMPLOYEES,
          variables: {
            filterEmployeeInput: {
              search: this.search,
              positionsIds: this.positionsIds && this.positionsIds.length > 0 ? this.positionsIds : [],
              sectionId: undefined,
              teamId: undefined,
              gender: undefined,
              page: isLoadAll ? undefined : this.page,
              pageSize: isLoadAll ? undefined : this.itemsPerPage,
              hasEmail: this.hasEmail,
            }
          },
        })

        const newItems = data.employees.employees.map((mapEmployee) => {
          return { text: `${mapEmployee.firstName} ${mapEmployee.lastName}`, value: mapEmployee.id }
        });
        this.items = [...this.items, ...newItems]
        
      } catch (error) {
        console.error('Error loading more items:', error)
        // Handle error appropriately
      } finally {
        this.loading = false
      }
    },

    toggleSelectVisible() {
      if (this.areVisibleItemsSelected) {
        // Completely deselect all visible items
        this.selectedItems = this.selectedItems.filter(selected =>
          !this.displayItems.some(item => item.value === selected)
        );
        
        this.selectedItemsObjects = this.selectedItemsObjects.filter(selected =>
          !this.displayItems.some(item => item.value === selected.value)
        );
      } else {
        // Select all visible items, avoiding duplicates
        const newSelections = this.displayItems.filter(item =>
          !this.selectedItems.some(selected => selected === item.value)
        );
        
        const newSelectionsObjects = this.displayItems.filter(item =>
          !this.selectedItemsObjects.some(selected => selected.value === item.value)
        );

        this.selectedItemsObjects = [...this.selectedItemsObjects, ...newSelectionsObjects];
        this.selectedItems = [...this.selectedItems, ...newSelections.map(item => item.value)];
      }
    },

    onScroll({ target }) {
      // Load more items when user scrolls near bottom
      const bottomOfList = target.scrollHeight - target.scrollTop === target.clientHeight
      if (bottomOfList) {
        this.loadMoreItems()
      }
    },

    removeItem(item) {
      const index = this.selectedItems.findIndex(selected => selected === item.value)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
        this.selectedItemsObjects.splice(index, 1)
      }
    },

    getEmployeeById(employee) {
      
      if (this.selectedItemsObjects.length > 0)
        return this.selectedItemsObjects.find(item => item.value === employee.value).text;

      return this.items.find(item => item.value === employee.value).text;
    },
  },
}
</script>

<style scoped>
.v-autocomplete {
  max-width: 100%;
}
</style>